import * as React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Fade from "react-reveal/Fade";

import linkedInIcon from "../../images/linkedin.png";
import githubIcon from "../../images/github.svg";
import DownloadVec from "../../images/download.svg";
import downloadResume from "../../assets/resume_marvinholleman.pdf";

const Contact = (props) => {
	return (
		<ContactContainer>
			<ContactInfoWrapper padding={props.padding}>
				<EmailAdress href={`mailto:${props.email}`}>{props.email}</EmailAdress>
				<br />
				<Phone>{props.phone}</Phone>
				<DownloadResume href={downloadResume}>
					Download resume <DownloadIcon src={DownloadVec} />
				</DownloadResume>
			</ContactInfoWrapper>
			<SocialIcons>
				<a
					href="https://nl.linkedin.com/in/marvin-holleman-303413b5"
					target="_blank"
				>
					<Fade delay={2000} right>
						<LinkedInIcon src={linkedInIcon} />
					</Fade>
				</a>
				<a href="https://github.com/marvinholleman" target="_blank">
					<Fade delay={2400} right>
						<GithubIcon src={githubIcon} />
					</Fade>
				</a>
			</SocialIcons>
		</ContactContainer>
	);
};

export default Contact;

const ContactContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 50px;

	@media (max-width: 768px) {
		flex-direction: column;
	}
`;

const ContactInfoWrapper = styled.div`
	margin: 0;
	padding: ${(props) => props.padding};
`;

const EmailAdress = styled.a`
	font-size: 1.1rem;
	color: #fff;
	text-decoration: none;
`;

const Phone = styled.a`
	font-size: 1.1rem;
`;

const LinkedInIcon = styled.img`
	max-width: 130px;
	bottom: 30px;
	position: relative;
	margin-right: 10px;

	@media (max-width: 768px) {
		margin-top: 80px;
	}
`;

const GithubIcon = styled.img`
	max-width: 130px;
	bottom: 30px;
	position: relative;

	@media (max-width: 768px) {
		margin-top: 80px;
	}
`;

const SocialIcons = styled.div`
	display: flex;
`;

const DownloadResume = styled.a`
	color: #fff;
	text-decoration: underline;
	top: 10px;
	position: relative;
	display: flex;
	align-items: center;
`;

const DownloadIcon = styled.img`
	max-width: 15px;
	margin-left: 10px;
`;
