import * as React from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

import Rellax from "rellax";

const Title = (props) => {
	return (
		<TitleElement
			className={props.class ? props.class : ""}
			padding={props.padding}
		>
			{props.children}
		</TitleElement>
	);
};

export default Title;

const TitleElement = styled.h2`
	font-family: "Futura Bold";
	overflow: hidden;
	font-size: 2.6rem;
	max-width: 300px;
	margin: 0;
	padding-left: 25px;
	padding: ${(props) => props.padding};
	padding-bottom: 30px;

	@media (max-width: 768px) {
		padding-bottom: 20px;
	}
`;
