import * as React from "react";
import styled, { css } from "styled-components";
import { Link } from "gatsby";
import { isMobile } from "react-device-detect";

import datafile from "../../projects.json";
import Title from "../Title";
import Container from "../Container";
import arrow from "../../images/caret-right.svg";

import { wheeling } from "../../helpers/scrollRight";

// markup
const Projects = (props) => {
	const [mobileDevice, setIsMobileDevice] = React.useState(0);

	React.useEffect(() => {
		wheeling();
	}, []);

	React.useEffect(() => {
		setIsMobileDevice(isMobile);
	}, [isMobile]);

	return (
		<ProjectsWrapper padding={props.padding}>
			<div>
				<Container>
					<Title padding="0 0 0 0">My work.</Title>
				</Container>
				<ProjectScrollContainer>
					<ScrollDivLeft isMobile={mobileDevice} className="scroll-left">
						<ArrowLeft
							isMobile={mobileDevice}
							src={arrow}
							className="arrow-left"
						/>{" "}
					</ScrollDivLeft>
					<ProjectsContainer className="projects-scroll">
						{datafile.projects.map((project) => {
							return (
								<Project to={`projects/${project.slug}`} className="project">
									<ProjectZoom>
										<ProjectThumbnail image={project.thumbnail}>
											<ProjectLogo src={project.logo} />
										</ProjectThumbnail>
									</ProjectZoom>
								</Project>
							);
						})}
					</ProjectsContainer>
					<ScrollDivRight isMobile={mobileDevice} className="scroll-right">
						<ArrowRight
							isMobile={mobileDevice}
							src={arrow}
							className="arrow-right"
						/>{" "}
					</ScrollDivRight>
				</ProjectScrollContainer>
			</div>
		</ProjectsWrapper>
	);
};

export default Projects;

const ProjectsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: ${(props) => props.padding};
	position: relative;
	overflow: hidden;
	/* 
	&::-webkit-scrollbar {
		display: none;
	} */
`;

const Project = styled(Link)`
	margin-left: 20px;
`;

const ProjectZoom = styled.div`
	overflow: hidden;
`;

// const Title = styled.h1`
// 	font-size: 2.6rem;
// `;

const ProjectsContainer = styled.div`
	display: flex;
	position: relative;
`;

const ProjectThumbnail = styled.div`
	background-image: url(${(props) => props.image});
	height: 510px;
	min-width: 330px;
	background-size: cover;

	box-shadow: inset 0 0 0 2000px rgb(0 0 0 / 40%);
	display: flex;
	justify-content: center;
	align-items: center;
	transition: ease 0.4s;

	&:hover {
		cursor: pointer;
		box-shadow: inset 0 0 0 2000px rgb(0 0 0 / 40%);
		transform: scale(1.2);
	}
`;

const ProjectLogo = styled.img`
	max-width: 200px;
`;

const ScrollDivLeft = styled.div`
	width: 150px;
	height: 100%;
	position: absolute;
	left: 0;
	z-index: 99;

	${({ isMobile }) =>
		isMobile &&
		`
		visibility: hidden;
		display: flex;
		justify-content: center;
		width: 75px;
		background-color: rgb(0, 0, 0, 0.4);
  `}
`;

const ScrollDivRight = styled.div`
	width: 150px;
	height: 100%;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 99;

	${({ isMobile }) =>
		isMobile &&
		`
		display: flex;
		justify-content: center;
		width: 75px;
		background-color: rgb(0, 0, 0, 0.4);
  `}
`;

const ProjectScrollContainer = styled.div`
	position: relative;
`;

const ArrowRight = styled.img`
	max-width: 50px;
	visibility: hidden !important;

	${({ isMobile }) =>
		isMobile &&
		`visibility: visible!important;
  `}
`;

const ArrowLeft = styled.img`
	max-width: 50px;
	transform: rotate(180deg);
	visibility: hidden !important;
`;
