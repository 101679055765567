import * as React from "react";
import styled from "styled-components";

const Text = (props) => {
	return <TextElement padding={props.padding}>{props.text}</TextElement>;
};

export default Text;

const TextElement = styled.p`
	font-size: 1.1rem;
	/* max-width: 300px; */
	margin: 0;
	padding: ${(props) => props.padding};
`;
