import * as React from "react";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import VisibilitySensor from "react-visibility-sensor";

import PageWrapper from "../components/PageWrapper";
import TextTitleComponent from "../components/TextTitleComponent";
import Projects from "../components/Projects";
import Container from "../components/Container";
import Title from "../components/Title";
import Text from "../components/Text";
import Contact from "../components/Contact";

import ProfileImage from "../images/portrait-1.jpg";

// markup
const IndexPage = () => {
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<PageWrapper>
			<Container>
				<IntroductionContainer>
					<IntroductionTextContainer>
						<Fade left>
							<IntroductionTitle>Welcome!</IntroductionTitle>
						</Fade>
						<Fade delay={1500} left>
							<IntroductionText>
								I’m <SecondaryText>Marvin</SecondaryText>, a creative developer,{" "}
								<SecondaryText>fishing</SecondaryText> enthousiastic and based
								in the area of <SecondaryText>Rotterdam</SecondaryText>.
							</IntroductionText>
						</Fade>
					</IntroductionTextContainer>
					<Fade delay={3000} right>
						<IntroductionImageContainer>
							<IntroductionImage src={ProfileImage} />
						</IntroductionImageContainer>
					</Fade>
				</IntroductionContainer>
				<TextTitleComponent padding="250px 0 0 0" />
			</Container>
			<Projects padding="250px 0 0 0" />
			<Container>
				<Title padding="250px 0 0 0">Get in touch.</Title>
				<Text text="Drop me an email or give me a call. I am always in for some new opportunities." />

				<Contact email="marvin@recnition.com" phone="+31 (0)6 27007292" />
			</Container>
		</PageWrapper>
	);
};

export default IndexPage;

const SecondaryText = styled.span`
	color: #839cb2;
`;

const IntroductionContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 60px;

	@media (max-width: 768px) {
		flex-direction: column;
	}
`;

const IntroductionTextContainer = styled.div`
	max-width: 500px;

	@media (max-width: 768px) {
		max-width: 330px;
	}
`;

const IntroductionTitle = styled.h1`
	font-family: "Futura Bold";
	font-size: 5rem;
	margin: 0;
	letter-spacing: 2px;
	font-weight: 100;

	@media (max-width: 768px) {
		font-size: 4rem;
	}
`;

const IntroductionText = styled.p`
	font-family: "futura medium";
	margin-top: 25px;
	font-size: 2.6rem;
`;

const IntroductionImageContainer = styled.div`
	height: 500px;

	@media (max-width: 768px) {
		height: 315px;
	}
`;

const IntroductionImage = styled.img`
	height: 100%;
`;

const DownloadResume = styled.a`
	color: #fff;
	text-decoration: underline;
	top: 10px;
	position: relative;
	display: flex;
	align-items: center;
`;

const DownloadIcon = styled.img`
	max-width: 15px;
	margin-left: 10px;
`;
