import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

let scrollPosition = 0;

const wheeling = () => {
	// let scrollPosition = 0;
	let projectsWidth = 0;
	const container = document.querySelector(".projects-scroll");
	const scrollDivLeft = document.querySelector(".scroll-left");
	const arrowLeft = document.querySelector(".arrow-left");
	const scrollDivRight = document.querySelector(".scroll-right");
	const arrowRight = document.querySelector(".arrow-right");

	const projects = container.children;
	const projectsArray = [...projects];
	const pageWidth = document.body.offsetWidth;

	let intervalIdLeft;
	let intervalIdRight;

	const intervalTimeout = 50;

	projectsArray.map((project) => {
		projectsWidth += project.offsetWidth;
	});

	if (
		/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
			navigator.userAgent
		)
	) {
		scrollDivLeft.addEventListener("click", function () {
			if (scrollPosition > 0) {
				scrollPosition = 20;
				container.style.transform = "translateX(" + scrollPosition + "px" + ")";
				scrollDivLeft.style.visibility = "hidden";
				arrowLeft.style.visibility = "hidden";
			} else {
				scrollPosition += 75;
				scrollDivRight.style.visibility = "visible";
				arrowRight.style.visibility = "visible";
				container.style.transform = "translateX(" + scrollPosition + "px" + ")";
			}
		});
		scrollDivRight.addEventListener("click", function () {
			if (Math.abs(scrollPosition) > projectsWidth - pageWidth + 150) {
				scrollDivRight.style.visibility = "hidden";
				arrowRight.style.visibility = "hidden";
			} else {
				scrollDivLeft.style.visibility = "visible";
				arrowLeft.style.setProperty("visibility", "visible", "important");
				// arrowLeft.style.visibility = "visible!important";
				scrollPosition -= 75;
				container.style.transform = "translateX(" + scrollPosition + "px" + ")";
			}
		});
	} else {
		scrollDivLeft.addEventListener("mouseenter", function () {
			intervalIdLeft = setInterval(function () {
				if (scrollPosition > 0) {
					scrollPosition = 20;
					container.style.transform =
						"translateX(" + scrollPosition + "px" + ")";
					scrollDivLeft.style.visibility = "hidden";
					arrowLeft.style.visibility = "hidden";
					clearInterval(intervalIdLeft);
				} else {
					if (
						/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
							navigator.userAgent
						)
					) {
						scrollPosition += 50;
						// clearInterval(intervalIdLeft);
					} else {
						scrollPosition += 10;
					}

					scrollDivRight.style.visibility = "visible";
					arrowRight.style.visibility = "visible";
					container.style.transform =
						"translateX(" + scrollPosition + "px" + ")";
				}

				// console.log(scrollPosition);
			}, intervalTimeout);
		});

		scrollDivLeft.addEventListener("mouseleave", function () {
			clearInterval(intervalIdLeft);
		});

		scrollDivRight.addEventListener("mouseenter", function () {
			intervalIdRight = setInterval(function () {
				if (Math.abs(scrollPosition) > projectsWidth - pageWidth + 150) {
					scrollDivRight.style.visibility = "hidden";
					arrowRight.style.visibility = "hidden";
					clearInterval(intervalIdRight);
				} else {
					scrollDivLeft.style.visibility = "visible";
					arrowLeft.style.visibility = "visible";
					scrollPosition -= 10;
					if (
						/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
							navigator.userAgent
						)
					) {
						scrollPosition -= 50;
						// clearInterval(intervalIdRight);
					} else {
						scrollPosition -= 10;
					}
					container.style.transform =
						"translateX(" + scrollPosition + "px" + ")";
				}
			}, intervalTimeout);
		});

		scrollDivRight.addEventListener("mouseleave", function () {
			clearInterval(intervalIdRight);
		});
	}
};
export { wheeling };
