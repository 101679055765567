import * as React from "react";
import styled, { css } from "styled-components";
import Fade from "react-reveal/Fade";
import Rellax from "rellax";
import VisibilitySensor from "react-visibility-sensor";
import { isMobile } from "react-device-detect";

import Title from "../Title";

// markup
const TextTitleComponent = (props) => {
	const [isVisible, setVisibility] = React.useState(false);
	const [entered, setEntered] = React.useState(false);

	const onChange = (visiblity) => {
		setVisibility(visiblity);
	};

	React.useEffect(() => {
		if (isVisible) {
			setEntered(true);
		}
	}, [isVisible]);

	return (
		<>
			<TextTitleWrapper padding={props.padding}>
				<VisibilitySensor onChange={onChange}>
					<TextContainer>
						<Text>
							During my career as a developer, I discovered many languages and
							techniques. Lately, I’ve been focusing more on front-end
							development. I’ve been working on different projects, mostly with
							cool front-end frameworks like React JS.
						</Text>
						<Text>
							As an assertive and extrovert person I love to combine being an
							entrepreneur and a developer.
						</Text>
						<Text>
							When I am out of office you can find me running in nature or
							fishing for the big fishes.
						</Text>
					</TextContainer>
				</VisibilitySensor>
				{isMobile ? (
					<Title class="animate">
						<SecondaryText>Creative developer</SecondaryText> with a passion for{" "}
						<SecondaryText>front-end</SecondaryText>.
					</Title>
				) : (
					<Fade delay={200} bottom>
						<Title>
							<SecondaryText>Creative developer</SecondaryText> with a passion
							for <SecondaryText>front-end</SecondaryText>.
						</Title>
					</Fade>
				)}
			</TextTitleWrapper>
			<Horizontal visible={entered} />
		</>
	);
};

export default TextTitleComponent;

const SecondaryText = styled.span`
	color: #839cb2;
`;

const TextTitleWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: ${(props) => props.padding};
	position: relative;
	/* margin-bottom: 20px; */

	/* &:after {
		content: "";
		width: 0;
		position: absolute;
		border-bottom: 1px solid #fff;
		bottom: 0;
		transition: width 1s ease-in;

		${(props) =>
		props.visible &&
		css`
			width: 150px;
		`};

		@media (max-width: 768px) {
			${(props) =>
		props.visible &&
		css`
			width: 320px;
		`};
		}
	} */

	@media (max-width: 768px) {
		flex-direction: column-reverse;
	}
`;

const TextContainer = styled.div`
	margin-top: 50px;

	@media (max-width: 768px) {
		margin-top: 0;
	}
`;

const Text = styled.p`
	max-width: 400px;
	font-size: 1.3rem;
	/* margin-top: 100px; */
	padding-right: 20px;
	max-width: 400px;
	font-size: 1.1rem;
	margin-top: 30px;
	line-height: 30px;
	/* text-align: justify; */
`;

const Horizontal = styled.hr`
	color: #fff;
	transition: width 1s ease-in;
	width: 0;

	${(props) =>
		props.visible &&
		css`
			width: 750px;
		`};

	@media (max-width: 768px) {
		${(props) =>
			props.visible &&
			css`
				width: 320px;
			`};
	}
`;
